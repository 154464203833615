$(function() {
    $('form').validator();

    $('.customCheckBoxBtn').click(function(){
        var group = $(this).parents('.customCheckBoxGroup');

        if(group.length > 0){
            group.find('.customCheckBoxBtn').removeClass('active').next('.customCheckBox').prop('checked', false);
        }

        if(!$(this).hasClass('customRadioBtn')){
            if($(this).hasClass('active')){
                $(this).removeClass('active').next('.customCheckBox').prop('checked', false);
            }else{
                $(this).addClass('active').next('.customCheckBox').prop('checked', true);
                $(this).parent().find('.with-errors').html('');
            }
        }else{
            $active = $(this).hasClass('active');

            if($active){
                $(this).removeClass('active').next('.customCheckBox').prop('checked', false);
            }else{
                $(this).addClass('active').next('.customCheckBox').prop('checked', true);
                $(this).parent().find('.with-errors').html('');
            }
        }
    });

    $('.image-popup').magnificPopup({
        type: 'image',
        closeOnContentClick: true,
        mainClass: 'mfp-img-mobile',
        image: {
            verticalFit: true
        },
        callbacks:{
            open: function(){
                $('.flow-menu').hide(300);
            },
            close: function(){
                $('.flow-menu').show(300);
            }
        }

    });

    $.each($('.popup-gallery'), function() {
        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1] // Will preload 0 - before current, and 1 after the current image
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.' /*,

                 titleSrc: function(item) {
                 return item.el.attr('title') + '<small>by Marsel Van Oosten</small>';
                 }
                 */
            },
            callbacks:{
                open: function(){
                    $('.flow-menu').hide(300);
                },
                close: function(){
                    $('.flow-menu').show(300);
                }
            }
        });
    });

    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade mfp-with-zoom',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false,
        zoom: {
            enabled: true,
            duration: 300 // don't foget to change the duration also in CSS
        },
        callbacks:{
            open: function(){
                $('.flow-menu').hide(300);
            },
            close: function(){
                $('.flow-menu').show(300);
            }
        }
    });
    $("#print").change(function() {
        if($('#print').prop("checked")) {
            $('.printBox').slideDown(200);
        }else {
            $('.printBox').slideUp(100);
        }
    });
    $("#szallit").change(function() {
        if($('#szallit').prop("checked")) {
            $('.szallitBox').slideDown(200);
        }else {
            $('.szallitBox').slideUp(100);
        }
    });
    $("#fefco").change(function() {
        var box = $('.fefcoBox');
        if($(this).val() != 0) {
            box.html('');
            if(!box.hasClass('active')){
                box.slideDown(200).addClass('active');
            }
            $(box).html('<img class="fefco-'+$(this).val()+'" src="/images/fefco/'+$(this).val()+'.png">');
        }else {
            box.slideUp(100).removeClass('active');
            box.html('');
        }
    });
});

var ajanlatCaptcha;
var contactCaptcha;
var fefcoCaptcha;
var sitekey='6LfmBxcTAAAAALc_e8f9RgH-P1_jTXUxzI0fT3D6';
var reCaptcha = function() {
    if (($("#ajanlatCaptcha").length > 0)) {
        ajanlatCaptcha = grecaptcha.render('ajanlatCaptcha', {
            'sitekey': sitekey
        });
    }
    if (($("#contactCaptcha").length > 0)) {
        contactCaptcha = grecaptcha.render('contactCaptcha', {
            'sitekey': sitekey
        });
    }
    if (($("#fefcoCaptcha").length > 0)) {
        fefcoCaptcha = grecaptcha.render('fefcoCaptcha', {
            'sitekey': sitekey
        });
    }
};